import React, { useEffect, useState } from 'react'

import { EuiButton, EuiConfirmModal, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { Campaign, CampaignType, GeoLocationTarget, IDemographics, LocationTarget, SegmentTarget } from 'api'
import { LocationTargetingType, useImportTargetingTemplateMutation, useUpdateCampaignSegmentsMutation, useUpdateCampaignXandrTargetingMutation } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { SegmentListBuilder } from 'components/Segments/SegmentListBuilder'
import { DemographicsTargeter } from 'components/Targeters/DemographicsTargeter'
import { ModernLocationTargeting } from 'features/customers/campaigns/targeting/ModernLocationTargeting'
import { useGetCampaignTargeting } from 'utils/useGetCampaignTargeting'

import { useGetAccountQuery } from '../../../../api/rtkQueryApi/opsApi/accountsApi'
import { useLazyGetDefaultTargetingByIdQuery } from '../../../../api/rtkQueryApi/opsApi/defaultTargetingApi'
import { useGetOrganizationQuery } from '../../../../api/rtkQueryApi/opsApi/organizationsApi'

export interface IModernTargetingTab {
  campaign: Campaign
}

export const ModernTargetingTab: React.FC<IModernTargetingTab> = ({ campaign }) => {
  const targeting = useGetCampaignTargeting(campaign.campaignId)
  const account = useGetAccountQuery(campaign.accountId)

  const organization = useGetOrganizationQuery(campaign.organizationId)
  const [getTargetingQuery, getTargetingQueryResult] = useLazyGetDefaultTargetingByIdQuery()
  const [importTargetingTemplateMutation, importTargetingTemplateMutationResult] = useImportTargetingTemplateMutation()

  const [locations, setLocations] = useState<GeoLocationTarget>()
  const [segments, setSegments] = useState<SegmentTarget[]>([])
  const [campaignType, setCampaignType] = useState<CampaignType>(CampaignType.Internet)
  const [locationTargetingType, setLocationTargetingType] = useState<LocationTargetingType>(LocationTargetingType.State)
  const [demographics, setDemographics] = useState<IDemographics>({
    ageRanges: [],
    genders: [],
    householdIncomes: []
  })

  const [showImportDefaultTargetingModal, setShowImportDefaultTargetingModal] = useState(false)

  const [updateCampaignSegments, updateCampaignSegmentsRequest] = useUpdateCampaignSegmentsMutation()
  const [updateXandrTargeting, updateXandrTargetingRequest] = useUpdateCampaignXandrTargetingMutation()

  useEffect(() => {
    if (targeting) {
      setLocations(targeting.locations)
      setLocationTargetingType(targeting.locationTargetingType)
      setDemographics(targeting.demographics)
      setCampaignType(targeting.campaignType)
    }
  }, [targeting])

  useEffect(() => {
    if (campaign.campaignType === CampaignType.TV) {
      setSegments(campaign.tvTargeting.segments ?? [])
    } else if (campaign.campaignType === CampaignType.Internet) {
      setSegments(campaign.advancedTargeting?.segments ?? [])
    }
  }, [campaign])

  const onChangeTargeting = (targeting: LocationTarget) => {
    setLocations(targeting)
  }

  const onAgeRangesChanged = (ageRanges: string[]) => {
    setDemographics({
      ...demographics,
      ageRanges: ageRanges
    })
  }

  const onGendersChanged = (genders: string[]) => {
    setDemographics({
      ...demographics,
      genders: genders
    })
  }

  const onHouseholdIncomesChanged = (householdIncomes: string[]) => {
    setDemographics({
      ...demographics,
      householdIncomes: householdIncomes
    })
  }

  const onSaveClick = () => {
    if (locations) {
      updateXandrTargeting({ campaignId: campaign.campaignId, locationTarget: locations, locationType: locationTargetingType, demographics: campaignType !== CampaignType.Billboard ? demographics : undefined }).then(() => {
        if (segments) {
          updateCampaignSegments({
            campaignId: campaign.campaignId,
            segments: segments
          })
        }
      })
    }
  }

  const onSegmentSelected = (segment: SegmentTarget) => {
    setSegments([...segments, segment])
  }

  const onSegmentRemoved = (segment: SegmentTarget) => {
    setSegments(segments.filter(s => s.id !== segment.id))
  }

  const onCopyDefaultTargeting = async () => {
    const targetingId = account.data?.defaultTargetingId ?? organization.data?.defaultTargetingId
    if (!targetingId) {
      return
    }
    try {
      const { data: targetingTemplate } = await getTargetingQuery({ id: targetingId })

      if (targetingTemplate) {
        const { locationTargeting, audienceTargeting, internetTargeting } = targetingTemplate
        await importTargetingTemplateMutation({
          id: campaign.campaignId,
          campaignType: campaign.campaignType,
          targetingTemplate: targetingTemplate
        })

        setLocations({
          ...locationTargeting,
          countries: []
        })
        setLocationTargetingType(locationTargeting.locationType as unknown as LocationTargetingType)
        setSegments(internetTargeting.segments ?? [])
        setDemographics({
          ageRanges: audienceTargeting.ageRanges,
          genders: audienceTargeting.genders,
          householdIncomes: audienceTargeting.householdIncomes
        })
        setShowImportDefaultTargetingModal(false)
      } else {
        console.warn(`No default targeting found with id ${targetingId}`)
      }
    } catch (e) {
      console.error(`Error copying default targeting with id ${targetingId}`, e)
    }
  }

  return (
    <React.Fragment>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow fullWidth>
            <EuiButton isLoading={account.isLoading || organization.isLoading} disabled={!account.data?.defaultTargetingId && !organization.data?.defaultTargetingId} iconType={'importAction'} onClick={() => setShowImportDefaultTargetingModal(true)}>
              Import Account Default Targeting
            </EuiButton>
          </EuiFormRow>
          {locations && (
            <EuiFormRow fullWidth label={'Locations'}>
              <ModernLocationTargeting
                locations={locations}
                selectedLocationType={locationTargetingType}
                onSelectedLocationTypeChanged={locationType => {
                  setLocationTargetingType(locationType)
                }}
                onLocationsChanged={onChangeTargeting}
              />
            </EuiFormRow>
          )}
          {campaignType !== CampaignType.Billboard && (
            <React.Fragment>
              <EuiSpacer size='xl' />
              <DemographicsTargeter ageRanges={demographics.ageRanges} genders={demographics.genders} householdIncomes={demographics.householdIncomes} onAgeRangesChanged={onAgeRangesChanged} onHouseholdIncomesChanged={onHouseholdIncomesChanged} onGendersChanged={onGendersChanged} />
              <EuiSpacer size='xl' />
              {locations?.uploadedAudienceUrl && (
                <React.Fragment>
                  <EuiTitle size='s'>
                    <span>Optional: Target Audience</span>
                  </EuiTitle>
                  <EuiFormRow fullWidth>
                    <EuiLink href={locations?.uploadedAudienceUrl} target='_blank'>
                      Uploaded Audience
                    </EuiLink>
                  </EuiFormRow>
                  <EuiSpacer size='xl' />
                </React.Fragment>
              )}
              <SegmentListBuilder segments={segments} campaignType={campaignType} onSegmentRemoved={onSegmentRemoved} onSegmentAdded={onSegmentSelected} />
            </React.Fragment>
          )}
          <EuiSpacer />
          <EuiFormRow>
            <EuiButton fill={true} onClick={onSaveClick} isLoading={updateXandrTargetingRequest.isLoading || updateCampaignSegmentsRequest.isLoading}>
              Save
            </EuiButton>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: 260 }}>
          <EuiText size='xs'>
            <h3>Best Practices</h3>
            <h5>Bulk Targeting by Zip/Address</h5>
            <p>If you are wanting to target a long list of Zip Codes or Street Addresses, you may want to consider using the Upload an Audience option instead of entering them all by hand. Either will work just fine, but the bulk upload feature may save you time.</p>
            <h5>About Traffic Prioritization</h5>
            <p>uses the characteristics you identify in Traffic Prioritization to favor people in the targeted location(s) who match the desired audience. For example, you could enter ‘golf’ to prioritize people in the specified geographic area interested in golfing.</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      {showImportDefaultTargetingModal && (
        <EuiModal onClose={() => setShowImportDefaultTargetingModal(false)}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Import Default Targeting From Account?</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>You will override these targeting settings with the account default targeting settings.</EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={onCopyDefaultTargeting} isLoading={importTargetingTemplateMutationResult.isLoading || getTargetingQueryResult.isLoading}>
              Confirm
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </React.Fragment>
  )
}
