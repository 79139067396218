import moment, { Moment } from 'moment'

import { EuiIcon, EuiPanel, EuiText, EuiTitle } from '@elastic/eui'

import { CampaignType } from '../../api'
import { formatCurrency } from '../../utils/AcFormatters'

export interface ICampaignPlanPanel {
  planItem: ICampaignPlanItem
}

export interface ICampaignPlanItem {
  campaignType: CampaignType
  description: string
  budget: number
  startDate: Moment
  endDate: Moment
}

export const CampaignPlanPanel: React.FC<ICampaignPlanPanel> = props => {
  const hasOccurred = props.planItem.startDate.isBefore(moment())
  return (
    <EuiPanel hasBorder={false} hasShadow={false}>
      <EuiTitle>
        <h3>
          {props.planItem.campaignType}
          {'   '}
          {hasOccurred && <EuiIcon type={'check'} color={'success'} size={'xl'} />}
        </h3>
      </EuiTitle>
      <EuiText size={'xs'}>
        <p>{props.planItem.description}</p>
      </EuiText>
      <EuiText>
        <p>{formatCurrency(props.planItem.budget)}</p>
      </EuiText>
      <EuiText>
        <p>Start Date: {props.planItem.startDate.format('MMMM DD, YYYY')}</p>
      </EuiText>
      <EuiText>
        <p>End Date: {props.planItem.endDate.format('MMMM DD, YYYY')}</p>
      </EuiText>
    </EuiPanel>
  )
}
