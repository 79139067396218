import { useEffect, useState } from 'react'

import { EuiCheckbox, EuiFlexGrid, EuiFlexItem, EuiFormRow, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'

import { CampaignType } from '../../api'
import { InternetTargeting, Segment, InventorySettings, DeviceSettings, DayPartitioning, FrequencyCapSettings, LocationTargeting, InternetLocationTargeting, TargetingType, AudienceTargeting, LocationType } from '../../api/entities/Targeting'
import { DEFAULT_AUDIENCE_TARGETING, DEFAULT_INTERNET_LOCATION_TARGETING } from '../../utils/TargetingFormDefaults'
import { AudienceTargetingForm } from './AudienceTargetingForm'
import { CampaignDeliveryForm } from './CampaignDeliveryForm'
import { LocationTargetingFields, LocationTargetingForm } from './LocationTargetingForm'
import { SegmentsForm } from './SegmentsForm'

type InternetTargetingFormProps = {
  internetTargeting: InternetTargeting
  onInternetTargetingChanged: (internetTargeting: InternetTargeting) => void
  targetingPriority: TargetingType
}

export const InternetTargetingForm = ({ internetTargeting, onInternetTargetingChanged, targetingPriority }: InternetTargetingFormProps) => {
  const [editCampaignDelivery, setEditCampaignDelivery] = useState(false)
  const [editLocationTargeting, setEditLocationTargeting] = useState(!!internetTargeting.locationTargeting)
  const [editDemographics, setEditDemographics] = useState(!!internetTargeting.audienceTargeting)
  const [selectedLocationType, setSelectedLocationType] = useState<LocationType>(internetTargeting.locationTargeting?.locationType ?? LocationType.Map)

  const onSegmentsChange = (segments: Segment[]) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      segments
    })
  }

  const onCampaignDeliveryChange = (campaignDelivery: { cpm?: number; dayParts?: DayPartitioning; frequencyCapSettings?: FrequencyCapSettings }) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      ...campaignDelivery
    })
  }

  const onInventorySettingsChange = (inventorySettings: InventorySettings) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      advancedInternetTargeting: {
        ...internetTargeting.advancedInternetTargeting,
        inventorySettings
      }
    })
  }

  const onDeviceSettingsChange = (deviceSettings: DeviceSettings) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      advancedInternetTargeting: {
        ...internetTargeting.advancedInternetTargeting,
        deviceSettings
      }
    })
  }

  const onLocationTargetingChange = (locationTargeting: LocationTargetingFields) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      locationTargeting: {
        ...locationTargeting,
        geoFences: locationTargeting?.geoFences ?? [],
        geoCircles: locationTargeting?.geoCircles ?? [],
        geoPolygons: locationTargeting?.geoPolygons ?? [],
        geoRectangles: locationTargeting?.geoRectangles ?? [],
        addresses: locationTargeting?.addresses ?? []
      }
    })
  }

  const onDemographicsChange = (audienceTargeting: AudienceTargeting) => {
    onInternetTargetingChanged({
      ...internetTargeting,
      audienceTargeting
    })
  }

  useEffect(() => {
    if (!editLocationTargeting) {
      onInternetTargetingChanged({
        ...internetTargeting,
        locationTargeting: undefined
      })
    } else if (!internetTargeting.locationTargeting) {
      onInternetTargetingChanged({
        ...internetTargeting,
        locationTargeting: DEFAULT_INTERNET_LOCATION_TARGETING
      })
    }
  }, [editLocationTargeting])

  useEffect(() => {
    if (!editDemographics) {
      onInternetTargetingChanged({
        ...internetTargeting,
        audienceTargeting: undefined
      })
    } else if (!internetTargeting.audienceTargeting) {
      onInternetTargetingChanged({
        ...internetTargeting,
        audienceTargeting: DEFAULT_AUDIENCE_TARGETING
      })
    }
  }, [editDemographics])

  const locationTargetingOptions = [LocationType.Country, LocationType.Region, LocationType.DMA, LocationType.City, LocationType.Map, LocationType.Zip]

  return (
    <>
      <EuiTitle size={'s'}>
        <h3>Location Targeting</h3>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiCheckbox id={'editInternetLocationTargeting'} onChange={() => setEditLocationTargeting(!editLocationTargeting)} checked={editLocationTargeting} label={'Override'} />
      {editLocationTargeting && <LocationTargetingForm selectedLocationType={selectedLocationType} onSelectedLocationTypeChange={setSelectedLocationType} locationTargeting={internetTargeting.locationTargeting ?? DEFAULT_INTERNET_LOCATION_TARGETING} onLocationTargetingChange={onLocationTargetingChange} includeLocationTypes={locationTargetingOptions} />}
      <EuiSpacer />
      <EuiTitle size={'s'}>
        <h3>Demographics</h3>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiCheckbox id={'editDemographicsTargeting'} onChange={() => setEditDemographics(!editDemographics)} checked={editDemographics} label={'Override'} />
      <EuiSpacer size='xs' />
      {editDemographics && <AudienceTargetingForm audienceTargeting={internetTargeting.audienceTargeting ?? DEFAULT_AUDIENCE_TARGETING} onAudienceTargetingChanged={onDemographicsChange} />}
      <EuiSpacer />
      <EuiTitle size={'s'}>
        <h3>Audience Targeting</h3>
      </EuiTitle>
      <EuiSpacer size='xs' />
      <SegmentsForm campaignType={CampaignType.Internet} segments={internetTargeting.segments ?? []} onSegmentsChange={onSegmentsChange} />
      <EuiSpacer />
      <EuiSwitch label={'Edit Campaign Delivery Strategy'} checked={editCampaignDelivery} onChange={() => setEditCampaignDelivery(!editCampaignDelivery)} />
      <EuiSpacer />
      {editCampaignDelivery && (
        <>
          <EuiTitle size='s'>
            <h3>Campaign Delivery Strategy</h3>
          </EuiTitle>
          <EuiSpacer />
          <CampaignDeliveryForm campaignDelivery={internetTargeting} onCampaignDeliveryChanged={onCampaignDeliveryChange} />
          <EuiSpacer />
          <EuiFormRow label={'Inventory and Device Settings'}>
            <EuiFlexGrid columns={2}>
              <EuiFlexItem>
                <EuiCheckbox
                  id={'targetApp'}
                  onChange={e =>
                    onInventorySettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.inventorySettings,
                      targetApp: e.target.checked
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.inventorySettings?.targetApp}
                  label={'Target App'}
                />
                <EuiCheckbox
                  id={'targetWeb'}
                  onChange={() =>
                    onInventorySettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.inventorySettings,
                      targetWeb: !internetTargeting.advancedInternetTargeting?.inventorySettings?.targetWeb
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.inventorySettings?.targetWeb}
                  label={'Target Web'}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiCheckbox
                  id={'pc'}
                  onChange={() =>
                    onDeviceSettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.deviceSettings,
                      targetPc: !internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPc
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPc}
                  label={'Target PC'}
                />
                <EuiCheckbox
                  id={'phone'}
                  onChange={() =>
                    onDeviceSettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.deviceSettings,
                      targetPhone: !internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPhone
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.deviceSettings?.targetPhone}
                  label={'Target Phone'}
                />
                <EuiCheckbox
                  id={'tablet'}
                  onChange={() =>
                    onDeviceSettingsChange({
                      ...internetTargeting.advancedInternetTargeting?.deviceSettings,
                      targetTablet: !internetTargeting.advancedInternetTargeting?.deviceSettings?.targetTablet
                    })
                  }
                  checked={internetTargeting.advancedInternetTargeting?.deviceSettings?.targetTablet}
                  label={'Target Tablet'}
                />
              </EuiFlexItem>
            </EuiFlexGrid>
          </EuiFormRow>
        </>
      )}
    </>
  )
}
