import moment from 'moment'
import React from 'react'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import { Account, CampaignType } from 'api'

import { CampaignPlanPanel, ICampaignPlanItem } from '../../../components/Campaign/CampaignPlanPanel'

export interface IAccountActionPlansTab {
  account: Account
}

export const AccountActionPlanTab: React.FC<IAccountActionPlansTab> = props => {
  const [campaignPlanItems] = React.useState<ICampaignPlanItem[]>([
    {
      campaignType: CampaignType.Internet,
      description: 'Use Internet Ads to reach bird watchers who are searching for bird watching tours in Eastern Utah.',
      budget: 1000,
      startDate: moment().add(-3, 'days'),
      endDate: moment().add(30, 'days')
    },
    {
      campaignType: CampaignType.TV,
      description: 'Use TV Campaign to target viewers in local markets. Viewers should be in arborous neighborhoods.',
      budget: 5000,
      startDate: moment().add(-3, 'days'),
      endDate: moment().add(30, 'days')
    },
    {
      campaignType: CampaignType.Internet,
      description: 'Use Internet Ads to reach bird watchers who are searching for bird watching tours in Eastern Utah.',
      budget: 2000,
      startDate: moment().add(31, 'days'),
      endDate: moment().add(61, 'days')
    },
    {
      campaignType: CampaignType.TV,
      description: 'Use TV Campaign to target viewers in local markets. Viewers should be in arborous neighborhoods.',
      budget: 10000,
      startDate: moment().add(31, 'days'),
      endDate: moment().add(61, 'days')
    },
    {
      campaignType: CampaignType.Internet,
      description: 'Use Internet Ads to reach bird watchers who are searching for bird watching tours in Eastern Utah.',
      budget: 3000,
      startDate: moment().add(62, 'days'),
      endDate: moment().add(92, 'days')
    },
    {
      campaignType: CampaignType.TV,
      description: 'Use TV Campaign to target viewers in local markets. Viewers should be in arborous neighborhoods.',
      budget: 15000,
      startDate: moment().add(62, 'days'),
      endDate: moment().add(92, 'days')
    },
    {
      campaignType: CampaignType.Internet,
      description: 'Use Internet Ads to reach bird watchers who are searching for bird watching tours in Eastern Utah.',
      budget: 4000,
      startDate: moment().add(93, 'days'),
      endDate: moment().add(123, 'days')
    },
    {
      campaignType: CampaignType.TV,
      description: 'Use TV Campaign to target viewers in local markets. Viewers should be in arborous neighborhoods.',
      budget: 20000,
      startDate: moment().add(93, 'days'),
      endDate: moment().add(123, 'days')
    }
  ])

  return (
    <React.Fragment>
      <EuiTitle size={'m'}>
        <h2>Action plan for {props.account.accountName}</h2>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel hasShadow={false} hasBorder={true}>
            <EuiTitle>
              <h3>Executive Summary :</h3>
            </EuiTitle>
            <EuiText>Bird Watchers specializes in training and outfitting the best bird watchers in Eastern Utah. If there is one thing they know, its birds. Bird Watchers has been in business for over 20 years and has a loyal following of bird watchers who come from all over the world to see the birds of Eastern Utah.</EuiText>
            <EuiSpacer size={'s'} />
            <EuiTitle>
              <h3>Marketing Strategy :</h3>
            </EuiTitle>
            <EuiText>Marketing strategy is to leverage the power of the internet to reach bird watchers who are looking for the best bird watching experience in Eastern Utah. The marketing strategy is to use Google Ads to reach bird watchers who are searching for bird watching tours in Eastern Utah.</EuiText>
            <EuiSpacer size={'s'} />
            <EuiTitle>
              <h3>Recommendations :</h3>
            </EuiTitle>
            <EuiText>1. Use TV Campaign to target viewers in local markets. Viewers should be in arborous neighborhoods.</EuiText>
            <EuiText>2. Use Internet Ads to reach bird watchers who are searching for bird watching tours in Eastern Utah.</EuiText>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel hasShadow={false} hasBorder={true}>
            <EuiTitle>
              <h2>Campaign Deployment Strategy</h2>
            </EuiTitle>
            {campaignPlanItems.map((item, index) => (
              <CampaignPlanPanel planItem={item} key={index} />
            ))}
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction={'column'} justifyContent={'flexStart'}>
            <EuiFlexItem grow={false}>
              <EuiButton iconType={'document'} fill style={{ maxWidth: 200, height: 80 }}>
                PDF
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton iconType={'training'} fill style={{ maxWidth: 200, height: 80 }}>
                Slides
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton iconType={'magnifyWithExclamation'} fill style={{ maxWidth: 200, height: 80 }}>
                Expert Review
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  )
}
