import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { EuiFormRow, EuiHealth, EuiSwitch } from '@elastic/eui'

import { Permission, PermissionType } from 'api'
import { useGetOrganizationQuery, useSetOrganizationPermissionsMutation } from 'api/rtkQueryApi/opsApi/organizationsApi'
import { RootState } from 'app/rootReducer'
import { useDebounceEffect } from 'utils/useDebounceEffect'

export interface IOrganizationPermissionsSection {
  organizationId: string
}

export const OrganizationPermissionsSection: React.FC<IOrganizationPermissionsSection> = props => {
  const getOrganizationQuery = useGetOrganizationQuery(props.organizationId)
  const [modifyOrganizationPermissions] = useSetOrganizationPermissionsMutation()
  const { userProfile } = useSelector((state: RootState) => state.app)

  const canEdit = userProfile ? userProfile?.roles.includes('adcritter-ops-csuite') || userProfile?.roles.includes('adcritter-ops-customersuccessmanager') : false

  const [enableAudienceUpload, setEnableAudienceUpload] = useState<boolean>(false)
  const [enableUseTemplates, setEnableUseTemplates] = useState<boolean>(false)
  const [enableUseTemplateBuilderCampaignCreation, setEnableUseTemplateBuilderCampaignCreation] = useState<boolean>(false)
  const [enableUseAdCritterAI, setEnableUseAdCritterAI] = useState<boolean>(false)
  const [enableShowCustomizablePixel, setEnableShowCustomizablePixel] = useState<boolean>(false)
  const [initiatedChange, setInitiatedChange] = useState<boolean>()

  useEffect(() => {
    if (getOrganizationQuery.data && getOrganizationQuery.data.permissions) {
      setEnableAudienceUpload(getOrganizationQuery.data.permissions.some(p => p.permissionType === PermissionType.UploadAudience))
      setEnableUseTemplates(getOrganizationQuery.data.permissions.some(p => p.permissionType === PermissionType.UseTemplates))
      setEnableUseAdCritterAI(getOrganizationQuery.data.permissions.some(p => p.permissionType === PermissionType.UseAdCritterAi))
      setEnableShowCustomizablePixel(getOrganizationQuery.data.permissions.some(p => p.permissionType === PermissionType.ShowCustomizablePixel))
      setEnableUseTemplateBuilderCampaignCreation(getOrganizationQuery.data.permissions.some(p => p.permissionType === PermissionType.UseTemplateBuilderCampaignCreation))
    }
  }, [getOrganizationQuery.data])

  useDebounceEffect(
    1000,
    () => {
      if (initiatedChange === undefined) {
        return
      }

      const permissions: Permission[] = []
      if (enableAudienceUpload) {
        permissions.push({ permissionType: PermissionType.UploadAudience })
      }

      if (enableUseTemplates) {
        permissions.push({ permissionType: PermissionType.UseTemplates })
      }

      if (enableUseTemplateBuilderCampaignCreation) {
        permissions.push({ permissionType: PermissionType.UseTemplateBuilderCampaignCreation })
      }

      if (enableUseAdCritterAI) {
        permissions.push({ permissionType: PermissionType.UseAdCritterAi })
      }

      if (enableShowCustomizablePixel) {
        permissions.push({ permissionType: PermissionType.ShowCustomizablePixel })
      }

      modifyOrganizationPermissions({
        organizationId: props.organizationId,
        permissions: permissions
      })
    },
    [initiatedChange, enableUseTemplates, enableAudienceUpload, enableUseAdCritterAI, enableShowCustomizablePixel]
  )

  const OnEnableAudienceUploadChange = () => {
    const newValue = !enableAudienceUpload
    setEnableAudienceUpload(newValue)
    setInitiatedChange(!initiatedChange)
  }

  const OnEnableUseTemplatesChange = () => {
    const newValue = !enableUseTemplates
    setEnableUseTemplates(newValue)
    setInitiatedChange(!initiatedChange)
  }

  const OnEnableUseTemplateBuilderCampaignCreationChange = () => {
    const newValue = !enableUseTemplateBuilderCampaignCreation
    setEnableUseTemplateBuilderCampaignCreation(newValue)
    setInitiatedChange(!initiatedChange)
  }

  const OnEnableUseAdCritterAIChange = () => {
    const newValue = !enableUseAdCritterAI
    setEnableUseAdCritterAI(newValue)
    setInitiatedChange(!initiatedChange)
  }

  const OnEnableShowCustomizablePixelChange = () => {
    const newValue = !enableShowCustomizablePixel
    setEnableShowCustomizablePixel(newValue)
    setInitiatedChange(!initiatedChange)
  }

  return (
    <React.Fragment>
      <EuiFormRow fullWidth>{canEdit ? <EuiSwitch label={'Allow Audience Upload'} checked={enableAudienceUpload} onChange={OnEnableAudienceUploadChange} /> : enableAudienceUpload ? <EuiHealth color={'success'}>Allow Audience Upload</EuiHealth> : <React.Fragment />}</EuiFormRow>
      <EuiFormRow fullWidth>{canEdit ? <EuiSwitch label={'Allow Use Templates'} checked={enableUseTemplates} onChange={OnEnableUseTemplatesChange} /> : enableUseTemplates ? <EuiHealth color={'success'}>Allow Use Templates</EuiHealth> : <React.Fragment />}</EuiFormRow>
      <EuiFormRow fullWidth>{canEdit ? <EuiSwitch label={'Allow Template Builder Campaign Creation'} checked={enableUseTemplateBuilderCampaignCreation} onChange={OnEnableUseTemplateBuilderCampaignCreationChange} /> : enableUseTemplateBuilderCampaignCreation ? <EuiHealth color={'success'}>Allow Template Builder Campaign Creation</EuiHealth> : <React.Fragment />}</EuiFormRow>
      <EuiFormRow fullWidth>{canEdit ? <EuiSwitch label={'Allow AdCritter AI'} checked={enableUseAdCritterAI} onChange={OnEnableUseAdCritterAIChange} /> : enableUseAdCritterAI ? <EuiHealth color={'success'}>Allow AdCritter AI</EuiHealth> : <React.Fragment />}</EuiFormRow>
      <EuiFormRow fullWidth>{canEdit ? <EuiSwitch label={'Show Customizable Pixel'} checked={enableShowCustomizablePixel} onChange={OnEnableShowCustomizablePixelChange} /> : enableShowCustomizablePixel ? <EuiHealth color={'success'}>Show Customizable Pixel</EuiHealth> : <React.Fragment />}</EuiFormRow>
    </React.Fragment>
  )
}
