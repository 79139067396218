import { useEffect, useState } from 'react'

import { EuiButton, EuiButtonIcon, EuiCallOut, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTextArea } from '@elastic/eui'

import { useLazyGetPostalCodesCsvQuery } from 'api/rtkQueryApi/opsApi/targetingApi'
import { AcTargeterSelection } from 'components/ACTools/AcTargeterSelection'
import { CityFinderXandr } from 'components/Finders/CityFinderXandr'
import { DMAFinderXandr } from 'components/Finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from 'components/Finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from 'components/Finders/RegionFinderXandr'

import { Address, City, Dma, GeoCircle, GeoFence, GeoPolygon, GeoRectangle, LocationType, PostalCode, Region } from '../../api/entities/Targeting'
import TargetingMap from '../TargetingMap'

export interface LocationTargetingFields {
  locationType: LocationType
  regions: Region[]
  dmas: Dma[]
  cities: City[]
  postalCodes: PostalCode[]
  uploadedAudienceUrl?: string
  geoFences?: GeoFence[]
  geoCircles?: GeoCircle[]
  geoPolygons?: GeoPolygon[]
  geoRectangles?: GeoRectangle[]
  addresses?: Address[]
}

interface LocationTargetingFormProps {
  locationTargeting: LocationTargetingFields
  onLocationTargetingChange: (locationTargeting: LocationTargetingFields) => void
  selectedLocationType: LocationType
  onSelectedLocationTypeChange: (locationType: LocationType) => void
  useDefault?: boolean
  includeLocationTypes?: LocationType[]
}

export const LocationTargetingForm = ({ locationTargeting, onLocationTargetingChange, includeLocationTypes = [], useDefault, selectedLocationType, onSelectedLocationTypeChange }: LocationTargetingFormProps) => {
  const [pastedZips, setPastedZips] = useState('')
  const [getZipsFromCsv, getZipsFromCsvQuery] = useLazyGetPostalCodesCsvQuery()
  const [zipsAsCsv, setZipsAsCsv] = useState('')
  const defaultLocationTypes = [LocationType.Country, LocationType.Region, LocationType.DMA, LocationType.Zip]

  const options = useDefault ? defaultLocationTypes : [...includeLocationTypes]

  useEffect(() => {
    locationTargeting.postalCodes && setZipsAsCsv(locationTargeting.postalCodes?.map(r => r.code).join(','))
  }, [locationTargeting.postalCodes])

  const onPasteZipsClick = async () => {
    const zips = pastedZips.replaceAll(/\s/g, '')
    const zipResult = await getZipsFromCsv({ codeCsv: zips })

    setPastedZips('')
    onLocationTargetingChange({
      ...locationTargeting,
      postalCodes: locationTargeting.postalCodes ? locationTargeting.postalCodes?.concat(zipResult.data ?? []) : zipResult.data ?? []
    })
  }

  return (
    <>
      {(options.includes(LocationType.Country) || options.includes(LocationType.USA)) && (
        <>
          <EuiRadio
            id='usa'
            name='locationType'
            value='Country'
            label={
              <EuiText size='s'>
                <strong>Target the USA</strong>
              </EuiText>
            }
            checked={selectedLocationType === LocationType.Country}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationType.Country })
              onSelectedLocationTypeChange(LocationType.Country)
            }}
          />
          <EuiSpacer size='xs' />
        </>
      )}
      {options.includes(LocationType.Region) && (
        <>
          <EuiRadio
            id='state'
            name='locationType'
            value='Region'
            label={
              <EuiText size='s'>
                <strong>Targeting Using State</strong> (one or more U.S. States)
              </EuiText>
            }
            checked={selectedLocationType === LocationType.Region}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationType.Region })
              onSelectedLocationTypeChange(LocationType.Region)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationType.DMA) && (
        <>
          <EuiRadio
            id='dma'
            name='locationType'
            value='DMA'
            label={
              <EuiText size='s'>
                <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
              </EuiText>
            }
            checked={selectedLocationType === LocationType.DMA}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationType.DMA })
              onSelectedLocationTypeChange(LocationType.DMA)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationType.City) && (
        <>
          <EuiRadio
            id='city'
            name='locationType'
            value='City'
            label={
              <EuiText size='s'>
                <strong>Targeting Using City</strong> (one or more City names)
              </EuiText>
            }
            checked={selectedLocationType === LocationType.City}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationType.City })
              onSelectedLocationTypeChange(LocationType.City)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationType.Zip) && (
        <>
          <EuiRadio
            id='zip'
            name='locationType'
            value='Zip'
            label={
              <EuiText size='s'>
                <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
              </EuiText>
            }
            checked={selectedLocationType === LocationType.Zip}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationType.Zip })
              onSelectedLocationTypeChange(LocationType.Zip)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {options.includes(LocationType.Map) && (
        <>
          <EuiRadio
            id='map'
            name='locationType'
            value='Map'
            label={
              <EuiText size='s'>
                <strong>Target on Map</strong> (draw the areas you want to target on a map)
              </EuiText>
            }
            checked={selectedLocationType === LocationType.Map}
            onChange={() => {
              onLocationTargetingChange({ ...locationTargeting, locationType: LocationType.Map })
              onSelectedLocationTypeChange(LocationType.Map)
            }}
          />
          <EuiSpacer size='s' />
        </>
      )}
      {selectedLocationType === LocationType.Region && options.includes(LocationType.Region) && (
        <>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr
              onOptionClick={region => {
                onLocationTargetingChange({ ...locationTargeting, regions: [...(locationTargeting.regions ?? []), region] })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.regions?.map(r => ({ key: r.id.toString(), label: r.name ?? '' })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, regions: locationTargeting.regions?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationType.DMA && options.includes(LocationType.DMA) && (
        <>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={(dma: Dma) => onLocationTargetingChange({ ...locationTargeting, dmas: [...(locationTargeting.dmas ?? []), dma] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, dmas: locationTargeting.dmas?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationType.City && options.includes(LocationType.City) && (
        <>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={(city: City) => onLocationTargetingChange({ ...locationTargeting, cities: [...(locationTargeting.cities ?? []), city] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.cities?.map(r => ({ key: r.id.toString(), label: `${r.name}, ${r.regionCode}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, cities: locationTargeting.cities?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationType.Zip && options.includes(LocationType.Zip) && (
        <>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr onOptionClick={(zip: PostalCode) => onLocationTargetingChange({ ...locationTargeting, postalCodes: [...(locationTargeting.postalCodes ?? []), zip] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth label={'Paste Zips as CSV'}>
            <EuiTextArea placeholder='Paste zips as comma seperated list (ex:"12345,23456")' value={pastedZips} onChange={e => setPastedZips(e.target.value)} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton size='s' onClick={onPasteZipsClick} isLoading={getZipsFromCsvQuery.isLoading}>
                  {getZipsFromCsvQuery.isLoading ? 'Getting Zips Codes...' : 'Submit Zips'}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, postalCodes: locationTargeting.postalCodes?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={zipsAsCsv}>{copy => <EuiButtonIcon title={'Copy as CSV'} iconType={'copy'} onClick={copy} />}</EuiCopy>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </>
      )}
      {selectedLocationType === LocationType.Map && options.includes(LocationType.Map) && (
        <>
          <EuiCallOut size='s' iconType='mapMarker' color='success' title='If you do not draw an area to target, the entire USA will be targeted by default.' />
          <EuiSpacer size='s' />
          <TargetingMap
            geoCircles={locationTargeting.geoCircles ?? []}
            addCircle={(circle: GeoCircle) => {
              onLocationTargetingChange({ ...locationTargeting, geoCircles: [...(locationTargeting.geoCircles ?? []), circle] })
            }}
            removeCircle={(id: string) => {
              onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.filter(c => c.id !== id) ?? [] })
            }}
            modifyCircle={(circle: GeoCircle) => {
              onLocationTargetingChange({ ...locationTargeting, geoCircles: locationTargeting.geoCircles?.map(c => (c.id === circle.id ? circle : c)) })
            }}
            geoRectangles={locationTargeting.geoRectangles ?? []}
            addRectangle={(rectangle: GeoRectangle) => {
              onLocationTargetingChange({ ...locationTargeting, geoRectangles: [...(locationTargeting.geoRectangles ?? []), rectangle] })
            }}
            removeRectangle={(id: string) => {
              onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.filter(r => r.id !== id) ?? [] })
            }}
            modifyRectangle={(rectangle: GeoRectangle) => {
              onLocationTargetingChange({ ...locationTargeting, geoRectangles: locationTargeting.geoRectangles?.map(r => (r.id === rectangle.id ? rectangle : r)) })
            }}
            geoPolygons={locationTargeting.geoPolygons ?? []}
            addPolygon={(polygon: GeoPolygon) => {
              onLocationTargetingChange({ ...locationTargeting, geoPolygons: [...(locationTargeting.geoPolygons ?? []), polygon] })
            }}
            removePolygon={(id: string) => {
              onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.filter(p => p.id !== id) ?? [] })
            }}
            modifyPolygon={(polygon: GeoPolygon) => {
              onLocationTargetingChange({ ...locationTargeting, geoPolygons: locationTargeting.geoPolygons?.map(p => (p.id === polygon.id ? polygon : p)) ?? [] })
            }}
          />
        </>
      )}
    </>
  )
}
