export interface Targeting {
  id: string
  organizationId: string
  name: string
  accountId?: string
  targetingPriority: TargetingType
  locationTargeting: LocationTargeting
  audienceTargeting: AudienceTargeting
  tvTargeting: TvTargeting
  billboardTargeting: BaseTargeting
  internetTargeting: InternetTargeting
  created?: moment.Moment
}

export enum TargetingType {
  Audience = 'Audience',
  Location = 'Location'
}

export type AgeRange = 'all' | '18_24' | '25_34' | '35_44' | '45_54' | '55_64' | '65'
export type Gender = 'all' | 'female' | 'male'
export type HouseholdIncome = 'all' | 'less50' | '50to100' | '100to150' | '150to200' | 'more200'

export interface AudienceTargeting {
  ageRanges: AgeRange[]
  genders: Gender[]
  householdIncomes: HouseholdIncome[]
  uploadedAudienceUrl?: string
}

export enum LocationType {
  USA = 'USA',
  DMA = 'DMA',
  City = 'City',
  Zip = 'Zip',
  Map = 'Map',
  Address = 'Address',
  Country = 'Country',
  Region = 'Region'
}

export interface LocationTargeting {
  locationType: LocationType
  regions: Region[]
  dmas: Dma[]
  cities: City[]
  postalCodes: PostalCode[]
  uploadedAudienceUrl?: string
}

export interface InternetLocationTargeting extends LocationTargeting {
  geoFences: GeoFence[]
  geoCircles: GeoCircle[]
  geoPolygons: GeoPolygon[]
  geoRectangles: GeoRectangle[]
  addresses: Address[]
}

export interface Dma {
  id: number
  name: string
}

export interface Region {
  id: number
  code: string
  name: string
}

export interface City {
  id: number
  name: string
  regionCode: string
}

export interface PostalCode {
  id: number
  code: string
  name: string
}

export interface GeoFence {
  placeId: string
  formattedAddress: string
  city?: string
  state?: string
  zip?: string
  lat: number
  lng: number
  radius: number
}

interface GeoPoint {
  lat: number
  lng: number
}

export interface GeoCircle {
  id: string
  center: GeoPoint
  radius: number
}

export interface GeoPolygon extends GeoCircle {
  points: GeoPoint[]
}

export interface GeoRectangle extends GeoCircle {
  northEast: GeoPoint
  southWest: GeoPoint
}

export interface Address {
  streetAddress?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export interface DayPartitioning {
  sunday: number[]
  monday: number[]
  tuesday: number[]
  wednesday: number[]
  thursday: number[]
  friday: number[]
  saturday: number[]
}

export enum FrequencyCapUnit {
  Hourly = 'Hourly',
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export interface FrequencyCapSettings {
  frequency?: number
  unit?: FrequencyCapUnit
}

export interface BaseTargeting {
  cpm?: number
  dayParts?: DayPartitioning
  frequencyCapSettings?: FrequencyCapSettings
  audienceTargeting?: AudienceTargeting
  locationTargeting?: LocationTargeting
}

export interface InternetTargeting extends BaseTargeting {
  segments?: Segment[]
  advancedInternetTargeting?: {
    advancedTargetingType?: string
    audienceType?: string
    retargetingType?: string
    inventorySettings?: InventorySettings
    deviceSettings?: DeviceSettings
  }
  locationTargeting?: InternetLocationTargeting
}

export interface InventorySettings {
  targetWeb?: boolean
  targetApp?: boolean
}

export interface DeviceSettings {
  targetPc?: boolean
  targetPhone?: boolean
  targetTablet?: boolean
}

export interface Segment {
  id: number
  name: string
  description?: string
  hierarchy: string[]
}

export interface TvTargeting extends BaseTargeting {
  segments?: Segment[]
}
